import { render, staticRenderFns } from "./CompanyDrawerSummaryTab.vue?vue&type=template&id=d9d3e650&scoped=true&"
import script from "./CompanyDrawerSummaryTab.vue?vue&type=script&lang=ts&"
export * from "./CompanyDrawerSummaryTab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9d3e650",
  null
  
)

export default component.exports